<template>
  <div class="list-info">
      <!-- 搜索框 -->
      <top-bar :title="'公告'" :left="true"></top-bar>
      <div class="search">
        <input v-model="searchValue" type="search" value="搜索" placeholder="请输入公告标题" class="search-content" @keyup.enter="onSearch">
        <img :src="require('@/assets/img/clear.png')" class="search-clear" @click="onCancel">
        <div class="search-btn" @click="onSearch">搜索</div>
      </div>

    <div class="filterBar">
      <van-row>
        <van-col span="24">
          <p @click="communityShow = !communityShow" :style="{color:communityShow?'#387FF5':'#666666'}">{{community || '本街道'}}
            <img :src="require(`@/assets/img/${communityShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
      </van-row>
    </div>

    <van-popup v-model="communityShow" position="bottom"  >
      <van-picker title="请选择" cancel-button-text="重置" value-key="label" show-toolbar :columns="communityList" @confirm="communityConfim" @cancel="communityCancel" />
    </van-popup>

      <div class="cont">
        <van-pull-refresh  v-model="pullLoading" success-text="刷新成功">
          <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" offset="10" v-if="dataList.length>0">
            <div class="totalCount">共有&nbsp;<span style="color:#387FF5">{{totalCount}}</span>&nbsp;条数据</div>
            <div class="list-item" v-for="item in dataList" :key="item.id" @click="getInfo(item.id)">
              <div class="content">
                <div class="content-text">
                  <div class="top-text">{{item.title}}</div>
                  <div class="bottom-text">{{item.createTime}}</div>
                </div>
                <div class="area-details">
                  <div class="notice-area">{{item.orgName}}</div>
                  <van-icon name="arrow" color="#666" class="content-icon"/>
                </div>
              </div>
            </div>
          </van-list>
          <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%"></van-image>
        </van-pull-refresh>

      </div>
  </div>
</template>

<script>
import topBar from "../../components/topBar/topBar.vue";
import {mapMutations} from 'vuex'
import {getBelongCommunity} from '@/utils/common'

export default {
 data(){
   return{
     communityShow: false,
     community: '',
     communityList: [],
     dataList:[
       // {title:"疫苗接种",
       //  time:"2021-09-09",
       //  area:"上塘街道"
       // }
     ],
      pullLoading: false,
      totalCount: '',
      searchValue: '',
      loading: false,
      finished: false,
      limit: 10,
      page: 0,
      orgId: 0,
   }
 },
 components:{topBar},
 created () {
   getBelongCommunity(this.$orgId, -1, (communityList) => {
     this.communityList = communityList
     this.communityList.unshift({
       label: '本街道',
       value: Number(this.$orgId)
     })
     this.orgId = this.$orgId
     this.getDataList()
   })
 },
 methods: {
    ...mapMutations,
   getInfo (id) {
     this.$store.commit('setNoticeId', id)
     this.$router.push({path: '/notice-info', query: {id}})
   },
   onSearch (val) {
      this.page = 0
      this.dataList = []
      this.getDataList()

   },
    onCancel() {
            this.page = 0
            this.dataList = []
            this.searchValue = ''
            this.getDataList()
        },
        onPullDownRefresh(e) {
          this.finished = false
          this.dataList = []
          this.page = 0;
          this.getDataList()
        },
   getDataList () {
     this.page++
            this.$toast.loading({duration: 0,message: '加载中...',forbidClick: true,});
            this.$http({
                url: this.$http.adornUrl('/wxapp/commonApp/notice/list'),
                method: 'post',
                params: this.$http.adornParams({
                    page: this.page,
                    limit: this.limit,
                    title: this.searchValue,  //搜索条件
                    orgId: this.orgId
                })
                }).then(({data})=> {
                    if (data.code == 0) {
                      this.pullLoading = false
                        console.log(data);
                        if (this.dataList.length == data.page.totalCount) {
                           this.finished = true
                       }
                       this.dataList = this.dataList.concat(data.page.list)
                       this.totalCount = data.page.totalCount
                        // 加载状态结束
                        this.dataList.sort(function(a,b){return a.createTime < b.createTime ? 1 : -1})
                       this.loading = false;
                        this.$toast.clear()
                    }
                    this.$toast.clear()
            },err=> {this.$toast.clear()})
        // 数据全部加载完成

   },
   // 所属社区 ->确认
   communityConfim(value){
     this.page = 0
     this.community = value.label
     this.orgId = value.value
     this.dataList = []
     this.getDataList()
     this.communityShow = false
   },
   // 所属社区->重置
   communityCancel(){
     this.page = 0
     this.dataList = []
     this.community = ''
     this.orgId = ''
     this.communityShow= false
     this.getDataList()
   },
 }
}
</script>

<style scoped lang="scss">
   /* .search-content{
     width: 550px;
     margin: 20px 200px 20px 20px;
     border-radius: 50px;
     height: 60px;
     border: 1px solid;
     border-color: #e4e7ed;
     text-indent: 30px;
     font-size: 24px;
   }
   .search-clear{
     position: absolute;
     top: 46px;
     right: 200px;
     font-size: 24px;
   }
  .search-btn{
    background-color: #409eff;
    color: white;
    font-size: 28px;
    border: 1px solid #409eff;
    border-radius: 30px;
    width: 120px;
    height: 60px;
    vertical-align: center;
    text-align: center;
    position: absolute;
    right: 50px;
    top: 20px;
    padding:12px 0;
  }  */
  .search {
    padding-bottom: 20px !important;
  }
  /* content */
  .cont .list-item{
    width: 680px;
    height: 140px;
    margin: 20px 20px;
    border-radius: 15px;
    background-color: white;
  }
  /* 左边内容 */
  .notice-title{
    float: left;
    margin: 20px 25px;
  }
  .top-text{
    font-size: 30px;
    color: black;
    font-weight: 600;
  }
  .bottom-text{
    margin-top:25px ;
    font-size: 24px;
    color:#909399 ;
  }
  /* 右边的内容 */
  .area-details{
    position: relative;
    flex: 1;
    margin-bottom: 1.2rem;
    .notice-area {
      position: absolute;
      right: 17%;
    }
    .van-icon {
      position: absolute;
      right: 20% !important;
      top: 100% !important;
      margin-top: 0.8rem;
    }
      //margin: 0.4rem 0 0 1.5rem;
  }
  .notice-area{
    font-size: 26px;
    color:#409eff;
  }
  .notice-details{
    margin-top:25px;
    font-size: 24px;
    text-align: end;
  }
   .filterBar, .dateBar {
     height: 0.8rem;
     line-height: 0.8rem;
   }
   .list-info .cont .list-item {
     margin: 0.26667rem 0.47333rem 0 !important;
     padding: 0;
     &:nth-child(2) {
       margin-top: 0 !important;
     }
   }
</style>
